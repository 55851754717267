import { create } from "zustand";
interface WalletPersonalState {
  walletPersonalSelected: Wallet_Personal_Current_Selected_Info | null;
  setWalletPersonalSelected: (
    data: Wallet_Personal_Current_Selected_Info | null
  ) => void;
  currentOptionSelected: Wallet_Personal_Current_Selected_Type;
  handleSetCurrentOptionSelected: (
    currentOptionSelected: Wallet_Personal_Current_Selected_Type
  ) => void;
  walletData: Wallet_Personal_API_response;
  setWalletData: (walletData: Wallet_Personal_API_response) => void;
  walletCertificateAvailable: boolean | undefined;
  setWalletCertificateAvailable: (
    walletCertificateAvailable: boolean | undefined
  ) => void;
  personalWalletSelected: number | null;
  setPersonalWalletSelected: (personalWalletSelected: number | null) => void;
  showDropDownOptions: Wallet_Personal_Current_Selected_Type;
  setShowDropDownOptions: (
    showDropDownOptions: Wallet_Personal_Current_Selected_Type
  ) => void;

  showActivateService: Wallet_Personal_Current_Selected_Type;
  setShowActivateService: (
    showActivateService: Wallet_Personal_Current_Selected_Type
  ) => void;

  walletDiscountCardsAvailable: boolean | undefined;
  setWalletDiscountCardsAvailable: (
    walletCertificateAvailable: boolean | undefined
  ) => void;
  showRequiredCertificate: boolean;
  setShowRequiredCertificate: (showRequiredCertificate: boolean) => void;
  initWalletData: boolean;
  setInitWalletData: (initWalletData: boolean) => void;
  currentDiscountCardAmount: number;
  setCurrentDiscountCardAmount: (discountCardAmount: number) => void;
  allDiscountCardRoomApplied: PersonalWalletSummaryRoomsType[];
  setAllDiscountCardRoomApplied: (
    allDiscountCardRoomApplied: PersonalWalletSummaryRoomsType[]
  ) => void;
  isLoadingWalletPrice: boolean;
  setIsLoadingWalletPrice: (isLoadingWalletPrice: boolean) => void;
  block_wallet: string;
  setBlock_wallet: (block_wallet: string) => void;
  availableDiscountCard: videoPreviewType;
  setAvailableDiscountCard: (availableDiscountCard: videoPreviewType) => void;
  personalWalletInfo: PersonalWalletInfo;
  setPersonalWalletInfo: (
    discountCardPersonalWalletInfo: PersonalWalletInfo
  ) => void;
  paymentCardByUser: {
    paymentMethods: Wallet_Personal_API_PaymentMethods_Type | null | undefined;
    walletOptions: Wallet_Personal_API_walletOptions | null | undefined;
  };
  setPaymentCardByUser: (responseWalletUser: {
    paymentMethods: Wallet_Personal_API_PaymentMethods_Type | null | undefined;
    walletOptions: Wallet_Personal_API_walletOptions | null | undefined;
  }) => void;
  discountCardByOrg: Get_Payment_Card_By_Org_API_Response_Type[] | undefined;
  setDiscountCardByOrg: (
    discountCardByOrd: Get_Payment_Card_By_Org_API_Response_Type[] | undefined
  ) => void;
  certificateByOrg: Get_Payment_Card_By_Org_API_Response_Type[] | undefined;
  setcertificateByOrg: (
    certificateByOrg: Get_Payment_Card_By_Org_API_Response_Type[] | undefined
  ) => void;
  hotelSelected: number | undefined;
  setHotelSelected: (hotelSelected: number | undefined) => void;
  globalCashCard: number;
  setGlobalCashCard: (globalCashCard: number) => void;
  globalCashCardCurrency: string;
  setGlobalCashCardCurrency: (globalCashCardCurrency: string) => void;
  executeGlobalCashCard: boolean;
  setExecuteGlobalCashCard: (executeGlobalCashCard: boolean) => void;
}

export const usePersonalWallet = create<WalletPersonalState>((set) => ({
  walletPersonalSelected: null,
  setWalletPersonalSelected: (
    value: Wallet_Personal_Current_Selected_Info | null
  ) => set({ walletPersonalSelected: value }),
  currentOptionSelected: "",
  handleSetCurrentOptionSelected: (
    value: Wallet_Personal_Current_Selected_Type
  ) => set({ currentOptionSelected: value }),
  walletData: { paymentMethods: null, walletOptions: null },
  setWalletData: (value: Wallet_Personal_API_response) =>
    set({ walletData: value }),
  walletCertificateAvailable: undefined,
  setWalletCertificateAvailable: (value: boolean | undefined) =>
    set({ walletCertificateAvailable: value }),
  personalWalletSelected: null,
  setPersonalWalletSelected: (value: number | null) =>
    set({ personalWalletSelected: value }),
  showDropDownOptions: "",
  setShowDropDownOptions: (value: Wallet_Personal_Current_Selected_Type) =>
    set({ showDropDownOptions: value }),
  showActivateService: "",
  setShowActivateService: (value: Wallet_Personal_Current_Selected_Type) =>
    set({ showActivateService: value }),
  walletDiscountCardsAvailable: undefined,
  setWalletDiscountCardsAvailable: (value: boolean | undefined) =>
    set({ walletDiscountCardsAvailable: value }),
  showRequiredCertificate: false,
  setShowRequiredCertificate: (value: boolean) =>
    set({ showRequiredCertificate: value }),
  initWalletData: true,
  setInitWalletData: (value: boolean) => set({ initWalletData: value }),
  currentDiscountCardAmount: 0,
  setCurrentDiscountCardAmount: (value: number) =>
    set({ currentDiscountCardAmount: value }),
  allDiscountCardRoomApplied: [],
  setAllDiscountCardRoomApplied: (value: PersonalWalletSummaryRoomsType[]) =>
    set({ allDiscountCardRoomApplied: value }),
  isLoadingWalletPrice: true,
  setIsLoadingWalletPrice: (value: boolean) =>
    set({ isLoadingWalletPrice: value }),
  block_wallet: "",
  setBlock_wallet: (value: string) => set({ block_wallet: value }),
  availableDiscountCard: {
    product_type: "",
    isAvailable: false,
  },
  setAvailableDiscountCard: (value: videoPreviewType) =>
    set({ availableDiscountCard: value }),
  personalWalletInfo: {
    product_type: "",
    certificateData: [],
    discountCardData: [],
  },
  setPersonalWalletInfo: (value: PersonalWalletInfo) =>
    set({ personalWalletInfo: value }),
  paymentCardByUser: {
    paymentMethods: undefined,
    walletOptions: undefined,
  },
  setPaymentCardByUser: (value: {
    paymentMethods: Wallet_Personal_API_PaymentMethods_Type | null | undefined;
    walletOptions: Wallet_Personal_API_walletOptions | null | undefined;
  }) => set({ paymentCardByUser: value }),
  discountCardByOrg: undefined,
  setDiscountCardByOrg: (
    value: Get_Payment_Card_By_Org_API_Response_Type[] | undefined
  ) => set({ discountCardByOrg: value }),
  certificateByOrg: undefined,
  setcertificateByOrg: (
    value: Get_Payment_Card_By_Org_API_Response_Type[] | undefined
  ) => set({ certificateByOrg: value }),
  hotelSelected: undefined,
  setHotelSelected: (value: number | undefined) =>
    set({ hotelSelected: value }),
  globalCashCard: 0,
  setGlobalCashCard: (value: number) => set({ globalCashCard: value }),
  globalCashCardCurrency: "",
  setGlobalCashCardCurrency: (value: string) =>
    set({ globalCashCardCurrency: value }),
  executeGlobalCashCard: false,
  setExecuteGlobalCashCard: (value: boolean) =>
    set({ executeGlobalCashCard: value }),
}));
