import { useEffect, useRef, useState } from "react";
import { Element, scroller } from "react-scroll";
import { Col, Row } from "react-bootstrap";
import { usePersonalWallet } from "@store/WalletPersonalStore";
import {
  ColInput,
  DateSpan,
  DateSpan2,
  DestinationInput,
  MainContainer,
  RowSearch,
  SearchBtn,
  SearchSpan,
  Separator,
  RowMain,
  // SpanIconPanxConfig,
  RowMainTittleMobile,
  MainDivTittleMobile,
  ColSearchInputMobile,
  SearchBtnTittleMobile,
  SpanPanConfigTittle,
  ColDate1,
  ColDate2,
  MainRowDate,
  ColBack,
  ColBtnSearch,
  ImgPointerDown,
  ColSearch,
  DivFlexB2BSearchOptions,
  DivLeftCliente,
  DivRightCliente,
  SearchClientContainer,
  SearchClientNationalityContainer,
  InputSearchClientContainer,
  DivImgSearch,
  ImgSearch,
  InputSearchAgent,
  DivInputSearchAgent,
  DivContainerAgents,
  AgentOption,
  AgentDivFlex,
  DivAgentImgSelected,
  AgentImgSelected,
  DivAgentName,
  SpanAgentName,
  DivDockBlue,
  DickBlue,
  //NationalityDivFlex,
  DivImgNationality,
  ImgNationality,
  DivInputSearchNationality,
  InputSearchNationality,
  DivContainerNationality,
  LabelNotFound,
  DivImgResultNotFound,
  ImgResultNotFound,
  NationalityOption,
  DivANationalityName,
  SpanNationalityName,
  CheckBoxPackage,
  DivCheckBoxPackage,
  DivLabelPackage,
  SpanLabelPackage,
  LabelErrorSearch,
  Img,
  ImgCalendar,
  ImgUser,
  ImgBack,
  ColInputDestination,
  DivBackShowOverlay,
  CloseIcon,
} from "./styles";
import axios from "axios";
import { LocationsOverlay } from "@components/LocationsOverlay";
import moment from "moment-timezone";
import { useGlobalContext } from "@hooks/useGlobalContext";
import { useRouter } from "next/router";
import { GuestConfigurationOverlay } from "@components/GuestConfigurationOverlay";
import { GuestConfigurationModal } from "@components/GuestConfigurationModal";
import {
  getDatesFromRange,
  getRoomsFromPaxString,
  //jumpToCalendarSearch,
} from "@helpers/helper";
import { useWhiteLabelLan } from "@hooks/useLayout";
import { checkCookies, getCookie, setCookies } from "cookies-next";
import { Recommended } from "./Recommended";
import { useBreakpoints } from "@hooks/useBreakpoints";
import { SEARCH_TYPE, IS_B2B } from "@helpers/config";
import { useAuth } from "@contexts/AuthContext";
import { B2BModalSignIn } from "./B2BModal2SignIn";
import { useMain } from "@contexts/mainContext";
import { fetcherAutoSuggest } from "@lib/api/home/getHotelAutoSuggest";
import useSWR from "swr";
import { useHotelSearchDetailStore } from "store/hotelSearchDetailStore";
import dynamic from "next/dynamic";
import { useLayoutStore } from "@store/layoutStore";
import { getDateStringFormat } from "@helpers/helperAds";
import { getParamsFromQuery } from "@helpers/helperHotel";

const Overlay = dynamic(() => import("@components/CalendarV2/Overlay"), {
  ssr: false,
});

type SearchProps = {
  isFloat?: boolean;
  updateData?: (request: searchByRoomOccupancyType) => void;
  hideDestination?: boolean;
  backText?: string;
  setRefresh: (flag: boolean) => void;
  refresh: boolean;
  currentSearch?: searchByRoomOccupancyType;
  showTravelText?: boolean;
};

export const Search: React.FC<SearchProps> = ({
  isFloat = true,
  updateData,
  hideDestination = false,
  backText,
  setRefresh,
  refresh,
  currentSearch,
  showTravelText = true,
}) => {
  const OFFSET_VALUE: number = 0;
  const {
    dateFormat,
    agentName,
    setAgentName,
    agentSelected,
    setAgentSelected,
    nationalityName,
    setNationalityName,
    nationalitySelected,
    setNationalitySelected,
    packageCheked,
    setPackageChecked,
    userType,
  } = useMain();
  const {
    setIsLoading,
    clearRecommendedCards,
    clearDataCards,
    clearDataHotels,
    setSearchHotelName,
  } = useHotelSearchDetailStore();
  const { weekStartsOn } = useLayoutStore((state) => ({
    weekStartsOn: state.weekStartsOn,
  }));
  const { setAdsCheckinDate } = useLayoutStore();
  const { setInitWalletData, setIsLoadingWalletPrice } = usePersonalWallet();
  const [searchText, setSearchText] = useState("");
  const { breakpoints } = useBreakpoints();
  const [isMobile, setIsMobile] = useState(false);
  const searchTypeArray = `${SEARCH_TYPE}`.split(",");
  //const [refresh, setRefresh] = useState(false);
  const router = useRouter();
  const { search, setSearch, setSearchDates } = useGlobalContext();
  const { getLangLabel } = useWhiteLabelLan();
  // Destinations section
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  // const [suggests, setSuggests] = useState<HotelAutoSuggestDataType[]>([]);
  const ref = useRef(null);
  // Destinations section end

  // Calendar section
  const [showCalendar, setShowCalendar] = useState(false);
  const [targetCalendar, setTargetCalendar] = useState(null);
  const refCalendar = useRef(null);

  // Guest Configuration section
  const [showGuest, setShowGuest] = useState(false);
  const [showGuestMobile, setShowGuestMobile] = useState(false);
  const [targetGuestConfig, setTargetGuestConfig] = useState(null);
  const refGestConfig = useRef(null);
  const maxRooms = 8;
  const [suggestedListcss, setSuggestedListcss] = useState(false);

  const getReqAutoSuggest = () => {
    const userGeolocation: CoordinatesAdsType | null = checkCookies(
      "userGeolocation"
    )
      ? JSON.parse(String(getCookie("userGeolocation")))
      : null;

    let lat;
    let lng;

    if (userGeolocation && userGeolocation.lat && userGeolocation.long) {
      lat = userGeolocation.lat;
      lng = userGeolocation.long;
    }
    const request = {
      url: "/api/booking/getAutoSuggest",
      args: {
        val: searchText,
        latitude: lat,
        longitude: lng,
      },
    };
    return request;
  };

  const { data: suggests, isLoading } = useSWR(
    searchText ? getReqAutoSuggest() : null,
    fetcherAutoSuggest
  );

  const handleGetSumObject = (
    data: GuestConfigurationType[],
    key: "adult" | "children" | "room"
  ) => {
    if (!Array.isArray(data)) {
      return 0;
    }
    if (key == "room") {
      return data.length;
    }
    const result = data?.reduce(function (
      prev: number,
      current: GuestConfigurationType
    ) {
      return prev + current[key];
    },
    0);
    return result;
  };

  const [exeValidationPaxConfig, setExeValidationPaxConfig] = useState(true);

  const [adultTotal, setAdultTotal] = useState(
    handleGetSumObject(search.paxConfig, "adult")
  );
  const [childrenTotal, setChildrenTotal] = useState(
    handleGetSumObject(search.paxConfig, "children")
  );

  const [roomTotal, setRoomTotal] = useState(
    handleGetSumObject(search.paxConfig, "room")
  );

  const { isLogged /* , currentUser */ } = useAuth();
  const [agents, setAgents] = useState<any>(null);
  const [agenstFilter, setAgentsFilter] = useState<any>(null);
  const [showAgentes, setShowAgents] = useState<boolean>(false);

  const [nationality, setNationality] = useState<any>(null);
  const [nationalityFilter, setNationalityFilter] = useState<any>(null);
  const [showNationality, setShowNationality] = useState<boolean>(false);
  const [showErrorSearch, setErrorSearch] = useState<string>("");
  const [currentDateSelected, setCurrentDateSelected] = useState<0 | 1>(0);

  //const [packageCheked, setPackageChecked] = useState<boolean>(false);

  const [showB2BModalSignIn, setShowB2BModalSignIn] = useState<boolean>(false);

  const handleShowB2BModalSignIn = (state: boolean) => {
    setShowB2BModalSignIn(state);
  };

  const handleDestinationsOverlay = (event: any) => {
    const value = event.target.value;
    setSearchText(value);

    // setSearch({ ...search, search_text: value });
    setTarget(event.target);

    if (value) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  const handleGuestConfiguration = (configuration: any) => {
    if (configuration.length <= maxRooms) {
      setSearch({ ...search, paxConfig: configuration });
      handleSetPersonTotal(configuration, "all");
    }
  };

  const handleSetPersonTotal = (configuration: any, type: string) => {
    if (type == "adult") {
      setAdultTotal(handleGetSumObject(configuration, type));
    } else if (type === "children") {
      setChildrenTotal(handleGetSumObject(configuration, type));
    } else if (type === "room") {
      setRoomTotal(handleGetSumObject(configuration, type));
    } else if (type === "all") {
      setAdultTotal(handleGetSumObject(configuration, "adult"));
      setChildrenTotal(handleGetSumObject(configuration, "children"));
      setRoomTotal(handleGetSumObject(configuration, "room"));
    }
  };

  const handleRemoveGuest = (id: any) => {
    if (search.paxConfig.length > 1) {
      const newList = search.paxConfig.filter(
        (_item, itemIndex) => itemIndex !== id
      );

      handleSetPersonTotal(newList, "all");
      setSearch({ ...search, paxConfig: newList });
    }
  };

  // const getAutoSuggest = async (val: string) => {
  //   try {
  //     const userGeolocation = checkCookies("userGeolocation")
  //       ? JSON.parse(String(getCookie("userGeolocation")))
  //       : null;

  //     let lat;
  //     let lng;

  //     if (userGeolocation && userGeolocation.lat && userGeolocation.long) {
  //       lat = userGeolocation.lat;
  //       lng = userGeolocation.long;
  //     }
  //     const res = await getHotelAutoSuggest(val, lat, lng);
  //     setSuggests(res);
  //   } catch (error) {
  //     //console.log(error);
  //     setSuggests([]);
  //   }
  // };

  const getDateRange = (date1: Date, date2: Date) => {
    const day = moment(date1, "YYYY-MM-DD");
    const day2 = moment(date2, "YYYY-MM-DD");

    const rangeDate = `${day.format("ddd, MMM DD YY")} - ${day2.format(
      "ddd, MMM DD YY"
    )}`;
    return rangeDate;
  };

  const insertCookies = (request2: any) => {
    if (!localStorage.getItem("destinationHistory")) {
      var json = [request2];
      localStorage.setItem("destinationHistory", JSON.stringify(json));
    } else {
      var existeDestino: boolean = false;
      var jsonTmp = JSON.parse(
        String(localStorage.getItem("destinationHistory") || "[{}]")
      );
      jsonTmp.map((obj: any) => {
        if (
          Number(obj.destination_id || 0) ==
          Number(request2.destination_id || 0)
        ) {
          existeDestino = true;
        }
      });
      if (!existeDestino) {
        jsonTmp.push({
          ...request2,
        });

        localStorage.setItem("destinationHistory", JSON.stringify(jsonTmp));
      }
    }
  };
  const getAgentsList = async () => {
    let json = { usr_type: userType };
    const response = await axios.post("/api/gettraveller", { json });
    //console.log("/api/gettraveller", { json }, response.data);
    setAgents(response?.data?.data?.data);
    setAgentsFilter(response?.data?.data?.data);
  };

  useEffect(() => {
    handleSetPersonTotal(search.paxConfig, "all");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (breakpoints.down("lg")) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [breakpoints]);

  useEffect(() => {
    if (IS_B2B && !agents && userType == 1) {
      getAgentsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agents, isLogged, userType]);

  useEffect(() => {
    const searchDetailPage = ["/search-detail"];
    if (searchDetailPage.includes(router.pathname)) {
      const query = router.query;
      const params = getParamsFromQuery(query);
      if (!!params?.startDate) {
        const dayFromUrl = moment(params.startDate, "YYYY-MM-DD").toDate();

        setAdsCheckinDate(getDateStringFormat(dayFromUrl));
        setCookies("adsCheckinDate", getDateStringFormat(dayFromUrl));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query]);

  const saveLatAndLong = (lat: number, long: number) => {
    // console.log("lat, long", lat, long);
    setCookies("searchLatitude", lat);
    setCookies("searchLongitude", long);
  };

  const handlerOnClick = async (updateRequest?: any) => {
    localStorage.setItem("wallet_block", "");
    localStorage.setItem("hlt_unique_id", "");
    localStorage.setItem("hlt_auto_focus_id", "");
    localStorage.setItem("hlt_auto_focus_position", "");
    localStorage.setItem("randomMath", "");
    setIsLoadingWalletPrice(true);
    setInitWalletData(true);
    setIsLoading(true);
    clearRecommendedCards();
    clearDataCards();
    clearDataHotels();
    saveLatAndLong(Number(search.latitude), Number(search.longitude));
    setSearchHotelName("");
    setAdsCheckinDate(getDateStringFormat(search.dates.checkIn));
    setCookies("adsCheckinDate", getDateStringFormat(search.dates.checkIn));
    let infonull = false;

    const datesFormated2 = getDatesFromRange(
      String(getDateRange(search.dates.checkIn, search.dates.checkOut))
    );
    const request = {
      count: 1000,
      destination_id: String(search.destination_id),
      search_text: searchTypeArray.includes(String(search.search_type))
        ? "100"
        : String(search.destination_id),
      search_type: Number(search.search_type),
      nationality:
        IS_B2B && userType == 1
          ? agentSelected?.nationalityCode
            ? agentSelected?.nationalityCode
            : nationalitySelected?.nationalityCode || "US"
          : "US",
      currency:
        String(
          getCookie("currency_code") ? getCookie("currency_code") : "USD"
        ).toUpperCase() || "USD",
      dateRange: getDateRange(search.dates.checkIn, search.dates.checkOut),
      check_in_date: datesFormated2.date1,
      check_out_date: datesFormated2.date2,
      language: getCookie("lan_code")
        ? String(getCookie("lan_code") || "en-US")
        : "en-US",
      traveller_id:
        IS_B2B && userType == 1 ? String(agentSelected?.id || null) : null,
      destination_name: search.search_text,
      minimum_stay: datesFormated2.diff,
      usr_type: userType,
      is_package_rate:
        IS_B2B && userType == 1 ? (packageCheked ? "true" : "false") : "false",
      distance: "km",
      rooms: getRoomsFromPaxString(search.paxConfig),
      /*  v_id: [
        searchTypeArray.includes(String(search.search_type))
          ? String(search.destination_id)
          : "",
      ], */
      paxConfig: search.paxConfig,
      latitude: Number(search.latitude),
      location: search.search_text,
      longitude: Number(search.longitude),
      dest_type: search.dest_type,
      display_price: "With Tax",
    };

    insertCookies({
      ...request,
      dates: {
        checkIn: search.dates.checkIn,
        checkOut: search.dates.checkOut,
      },
    });

    const query: SearchQueryType = {
      dest_type: search.dest_type || "", //21
      dateRange: getDateRange(search.dates.checkIn, search.dates.checkOut),
      destination_id: String(search.destination_id),
      latitude: String(search.latitude),
      location: search.search_text || "",
      longitude: String(search.longitude),
      paxConfig: JSON.stringify(search.paxConfig),
      search_type: String(search.search_type),
      ...updateRequest,
    };

    if (router.pathname === "/hotel-details") {
      /*if (updateData) {
        const datesFormated = getDatesFromRange(
          String(getDateRange(search.dates.checkIn, search.dates.checkOut))
        );
        const request = {
          search_text: searchTypeArray.includes(String(search.search_type))
            ? "100"
            : String(search.destination_id),

          search_type: Number(search.search_type),
          nationality:
            IS_B2B && userType == 1
              ? agentSelected?.nationalityCode
                ? agentSelected?.nationalityCode
                : nationalitySelected?.nationalityCode || "US"
              : "US",
          currency:
            String(
              getCookie("currency_code") ? getCookie("currency_code") : "USD"
            ).toUpperCase() || "USD",
          check_in_date: datesFormated.date1,
          check_out_date: datesFormated.date2,
          language: getCookie("lan_code")
            ? String(getCookie("lan_code") || "en-US")
            : "en-US",
          traveller_id:
            IS_B2B && userType == 1 ? String(agentSelected?.id || null) : null,
          destination_name: search.search_text,
          minimum_stay: datesFormated.diff,
          usr_type: userType,
          is_package_rate:
            IS_B2B && userType == 1
              ? packageCheked
                ? "true"
                : "false"
              : "false",
          distance: "km",
          rooms: getRoomsFromPaxString(search.paxConfig),
          v_id: [
            searchTypeArray.includes(String(search.search_type))
              ? String(search.destination_id)
              : "",
          ],
          latitude: Number(search.latitude),
          location: search.search_text,
          longitude: Number(search.longitude),
          display_price: "With Tax",
          paxConfig: JSON.stringify(search.paxConfig),
          ...updateRequest,
        };
        await updateData(request);
      }*/

      if (searchText != "") {
        router.push(
          {
            pathname: "/search-detail",
            query: {
              ...router.query,
              ...query,
              next: undefined,
            },
          },
          undefined,
          { shallow: true }
        );
      } else {
        infonull = true;
      }
    } else {
      if (window.location.pathname != "/search-detail") {
        router.push({
          pathname: "/search-detail",
          query: {
            ...router.query,
            ...query,
            next: undefined,
          },
        });
        return;
      }
      router
        .push(
          {
            pathname: "/search-detail",
            query: {
              ...router.query,
              ...query,
              next: undefined,
            },
          },
          undefined,
          { shallow: true, scroll: false }
        )
        .finally(() => {
          router.reload();
        });
    }
    if (!infonull) {
      if (updateData) {
        const datesFormated = getDatesFromRange(
          String(getDateRange(search.dates.checkIn, search.dates.checkOut))
        );
        const request = {
          search_text: searchTypeArray.includes(String(search.search_type))
            ? "100"
            : String(search.destination_id),
          search_type: Number(search.search_type),
          nationality:
            IS_B2B && userType == 1
              ? agentSelected?.nationalityCode
                ? agentSelected?.nationalityCode
                : nationalitySelected?.nationalityCode || "US"
              : "US",
          currency:
            String(
              getCookie("currency_code") ? getCookie("currency_code") : "USD"
            ).toUpperCase() || "USD",
          check_in_date: datesFormated.date1,
          check_out_date: datesFormated.date2,
          language: getCookie("lan_code")
            ? String(getCookie("lan_code") || "en-US")
            : "en-US",
          traveller_id:
            IS_B2B && userType == 1 ? String(agentSelected?.id || null) : null,
          destination_name: search.search_text,
          minimum_stay: datesFormated.diff,
          usr_type: userType,
          is_package_rate:
            IS_B2B && userType == 1
              ? packageCheked
                ? "true"
                : "false"
              : "false",
          distance: "km",
          rooms: getRoomsFromPaxString(search.paxConfig),
          v_id: [
            searchTypeArray.includes(String(search.search_type))
              ? String(search.destination_id)
              : "",
          ],
          latitude: Number(search.latitude),
          location: search.search_text,
          longitude: Number(search.longitude),
          display_price: "With Tax",
          ...updateRequest,
        };
        await updateData(request);
      }
    }
  };

  const BtnSearchComponent = ({ theme }: any) => {
    return (
      <SearchBtn
        theme={{
          isMobile: theme.isMobile2,
          ishoteldetails: getStatusRecommended(),
        }}
        variant="primary"
        onClick={async (e: any) => {
          const pathName = router.pathname;

          e.preventDefault();
          if (IS_B2B && userType == 1) {
            setErrorSearch("");
            if (!isLogged) {
              setShowB2BModalSignIn(true);
            } else {
              if (
                search.search_text &&
                (agentSelected?.client_agent_id ||
                  nationalitySelected?.nationalityId)
              ) {
                await handlerOnClick();
              } else {
                if (!search.search_text) {
                  setErrorSearch(
                    `${getLangLabel("PLEASE SELECT DESTINATION")}`
                  );
                } else {
                  setErrorSearch(
                    `${getLangLabel("PLEASE SELECT CLIENT OR NATIONALITY")}`
                  );
                }
              }
            }
          } else if (
            searchText ||
            search.search_text ||
            pathName == "/hotel-details"
          ) {
            await handlerOnClick();
          }
        }}
        className="boton_search search-white"
      >
        <SearchSpan theme={{ isMobile: theme.isMobile2 }}>
          {theme.isMobile2 && (
            <SearchBtnTittleMobile>
              {getLangLabel("Search")}
            </SearchBtnTittleMobile>
          )}
        </SearchSpan>
      </SearchBtn>
    );
  };

  const getStatusRecommended = () => {
    const pathName = router.pathname;
    let status = false;
    switch (pathName) {
      case "/hotel-details":
        status = true;
        break;

      default:
        status = false;
        break;
    }
    return status;
  };

  const getColumnsSize = () => {
    if (isFloat || !backText) {
      return {
        lg: IS_B2B && userType == 1 ? 3 : 4,
      };
    }
    return {
      lg: 3,
    };
  };

  const filterAgents = (value: string) => {
    if (!value) {
      setAgentsFilter(agents);
      setAgentSelected({
        id: 0,
        client_agent_id: 0,
        trav_title: "",
        first_name: "",
        last_name: "",
        email: "",
        nationalityCode: "",
      });
      setAgentName("");
    } else {
      let tmp: any[] = [];
      agents?.map((obj: any) => {
        let firstName = String(obj?.first_name).toLowerCase();
        let firstLastName = String(obj?.last_name).toLowerCase();
        let completeName = `${firstName} ${firstLastName}`;
        let valueInput = value.toLowerCase();
        if (
          firstName.indexOf(valueInput) > -1 ||
          firstLastName.indexOf(valueInput) > -1 ||
          completeName.indexOf(valueInput) > -1
        ) {
          tmp.push(obj);
        }
      });
      setAgentsFilter(tmp);
    }
  };

  const getNationality = async () => {
    let json = {};
    const response = await axios.post("/api/getNationalityCat", { json });
    setNationality(response?.data?.data?.data);
    setNationalityFilter(response?.data?.data?.data);
  };

  const filterNationality = (value: string) => {
    if (!value) {
      setNationalityFilter(nationality);
      setNationalitySelected({
        nationalityId: 0,
        nationalityName: "",
        nationalityCode: "",
      });
    } else {
      let tmp: any[] = [];
      nationality?.map((obj: any) => {
        let nationalityName = String(obj?.nationalityName).toLowerCase();
        let valueInput = value.toLowerCase();
        if (nationalityName.indexOf(valueInput) > -1) {
          tmp.push(obj);
        }
      });
      setNationalityFilter(tmp);
    }
  };

  useEffect(() => {
    if (IS_B2B && !nationality && userType == 1) {
      getNationality();
    }
  }, [nationality, isLogged, userType]);

  // useEffect(() => {
  //   if (searchText && isMountedRef.current) {
  //     getAutoSuggest(searchText);
  //   }
  // }, [isMountedRef, searchText]);
  //console.log({ agentSelected });
  const [dropDownAgentsShow, setDropDownAgentsShow] = useState<boolean>(false);
  useEffect(() => {
    if (IS_B2B && userType == 1) {
      setDropDownAgentsShow(true);
    } else {
      setDropDownAgentsShow(false);
    }
  }, [userType]);
  useEffect(() => {
    if (exeValidationPaxConfig && router.query) {
      const query = router.query;
      if (query.paxConfig) {
        const paxConfig = JSON.parse(String(query.paxConfig || "{}"));
        setExeValidationPaxConfig(false);
        setAdultTotal(handleGetSumObject(paxConfig, "adult"));
        setChildrenTotal(handleGetSumObject(paxConfig, "children"));
        setRoomTotal(handleGetSumObject(paxConfig, "room"));
      }
    }
  }, [exeValidationPaxConfig, router]);

  const jumpTo = (to: string, offset = -10) => {
    if (
      breakpoints.up("lg") &&
      (router.pathname == "/" || router.pathname == "/home")
    ) {
      scroller.scrollTo(to, {
        duration: 1000,
        delay: 100,
        smooth: true,
        offset,
      });
    }
  };

  return (
    <MainContainer theme={{ isFloat, userType }} fluid={true}>
      {showTravelText && (
        <RowMainTittleMobile>
          <MainDivTittleMobile className="maintitlemobile">
            {getLangLabel("Travel the world with us!")}
          </MainDivTittleMobile>
        </RowMainTittleMobile>
      )}
      <RowSearch theme={{ isFloat }} className="justify-content-md-center">
        <ColSearch
          theme={{
            width: getStatusRecommended() ? "1440px" : "1300px",
            isFloat,
            dropDownAgentsShow: dropDownAgentsShow,
            dropDownRecomendedHotels: getStatusRecommended(),
          }}
          xs={12}
        >
          <Row>
            <Col
              style={{ margin: "auto" }}
              xs={12}
              lg={IS_B2B && userType == 1 ? 10 : 11}
            >
              <Element name="htl-destination-input">
                <RowMain theme={{ isFloat, IS_B2B, userType }}>
                  {backText && (
                    <ColBack
                      className="LabelColor"
                      xs={12}
                      md={6}
                      lg={3}
                      theme={{ isMobile }}
                      onClick={() => {
                        clearRecommendedCards();
                        clearDataCards();
                        clearDataHotels();
                        setIsLoading(true);
                        setTimeout(() => {
                          router.back();
                        }, 150);
                      }}
                    >
                      <ImgBack
                        className="imgDestination"
                        src={"/images/wallet/BackBlue.svg"}
                      />
                      {backText}
                    </ColBack>
                  )}

                  <Recommended
                    show={getStatusRecommended()}
                    currentSearch={currentSearch}
                    isMobile={isMobile}
                  />

                  <ColInputDestination
                    style={{ display: "flex" }}
                    ref={ref}
                    theme={{
                      hide: hideDestination,
                      width: getStatusRecommended() ? "18rem" : "initial",
                      destination: show,
                      isMobile,
                      suggestedListcss,
                    }}
                    xs={12}
                    lg={getColumnsSize().lg}
                  >
                    <Img
                      className="imgDestination"
                      src={"/images/autocomplete-icon/iconSearchLabel.svg"}
                      theme={{
                        destination: show,
                        isMobile,
                        suggestedListcss,
                      }}
                    ></Img>
                    <DestinationInput
                      className={`${
                        breakpoints.down("lg") && show
                          ? "form-control location-red  destinationmobile inputsearchplaceholder"
                          : "form-control destination location-red "
                      }`}
                      theme={{
                        destination: show,
                        isMobile,
                        suggestedListcss,
                      }}
                      type={"text"}
                      placeholder={
                        isMobile
                          ? `${getLangLabel("ENTER DESTINATION OR PROPERTY")}`
                          : `${getLangLabel("DESTINATION")}`
                      }
                      value={String(searchText || search.search_text)}
                      // defaultValue={search.search_text || ""}
                      onChange={(event) => {
                        // const value = event.target.value;
                        // setSearchText(value);

                        handleDestinationsOverlay(event);
                      }}
                      onClick={(event) => {
                        jumpTo("htl-destination-input", OFFSET_VALUE);
                        if (localStorage.getItem("destinationHistory")) {
                          handleDestinationsOverlay(event);
                          setShow(true);
                          setRefresh(!refresh);
                        }
                        setSearchText("");
                        setSearch({ ...search, search_text: "" });
                        // setTimeout(() => {
                        //   if (isMobile) {
                        //     document
                        //       ?.getElementById("input-destination-mobil")
                        //       ?.focus();
                        //   }
                        // }, 200);
                      }}
                    />
                    <DivBackShowOverlay
                      className={`${
                        breakpoints.down("lg") && show
                          ? "divbackshowoverlay"
                          : ""
                      }`}
                      theme={{
                        destination: show,
                        suggestedListcss,
                        isMobile,
                      }}
                    >
                      <CloseIcon
                        src="/images/general/mainClose.svg"
                        onClick={() => setShow(false)}
                      />
                    </DivBackShowOverlay>
                  </ColInputDestination>
                  <ColInput
                    ref={refCalendar}
                    theme={{ isMobile }}
                    onClick={(event: any) => {
                      setTargetCalendar(event.target);
                      setShowCalendar(true);
                      //jumpToCalendarSearch();
                    }}
                    xs={12}
                    lg={getColumnsSize().lg}
                  >
                    <MainRowDate>
                      <ColDate1
                        xs={6}
                        className="fecha_pre"
                        onClick={() => {
                          jumpTo("htl-destination-input", OFFSET_VALUE);
                          setCurrentDateSelected(0);
                        }}
                      >
                        <ImgCalendar
                          className="date-picker-search"
                          src={"/images/calendar/calsearch.svg"}
                        ></ImgCalendar>
                        <DateSpan className="calendar-red calendardate">
                          {dateFormat && (
                            <>
                              {moment(search.dates.checkIn).format(dateFormat)}
                            </>
                          )}
                        </DateSpan>
                        <Separator />
                      </ColDate1>
                      <ColDate2
                        xs={6}
                        className="fecha_post"
                        onClick={() => {
                          jumpTo("htl-destination-input", OFFSET_VALUE);
                          setCurrentDateSelected(1);
                        }}
                      >
                        <ImgCalendar
                          className="date-picker-search"
                          src={"/images/calendar/calsearch.svg"}
                        ></ImgCalendar>
                        <DateSpan2 className="calendar-red calendardate">
                          {dateFormat && (
                            <>
                              {moment(search.dates.checkOut).format(dateFormat)}
                            </>
                          )}
                        </DateSpan2>
                      </ColDate2>
                    </MainRowDate>
                  </ColInput>
                  <ColInput
                    theme={{
                      isMobile,
                      width: getStatusRecommended() ? "17rem" : "initial",
                    }}
                    ref={refGestConfig}
                    onClick={(event: any) => {
                      jumpTo("htl-destination-input", OFFSET_VALUE);
                      setTargetGuestConfig(event.target);
                      if (isMobile) {
                        setShowGuestMobile(true);
                      } else {
                        setShowGuest(true);
                      }
                    }}
                    xs={12}
                    lg={getColumnsSize().lg}
                  >
                    <SpanPanConfigTittle className="adults-childs usercss-red">
                      <ImgPointerDown />
                      <ImgUser
                        className="imgUser"
                        src={"/images/PanxConfigMobileIcons/panxConfigIcon.svg"}
                      ></ImgUser>
                      {/* <SpanIconPanxConf ig /> */}
                      {adultTotal} {getLangLabel("ADULTS")} + {childrenTotal}{" "}
                      {childrenTotal < 2
                        ? getLangLabel("CHILD")
                        : getLangLabel("CHILDREN")}
                      , {roomTotal} {getLangLabel("ROOM")}
                    </SpanPanConfigTittle>
                  </ColInput>
                  <ColInput
                    xs={12}
                    lg={3}
                    hidden={
                      IS_B2B && userType == 1 && !getStatusRecommended()
                        ? false
                        : true
                    }
                  >
                    <DivFlexB2BSearchOptions>
                      <DivLeftCliente>
                        <SearchClientContainer theme={{ isMobile }}>
                          <InputSearchClientContainer>
                            <DivImgSearch>
                              <ImgSearch />
                            </DivImgSearch>
                            <DivInputSearchAgent>
                              <InputSearchAgent
                                type={"text"}
                                placeholder={`${getLangLabel("SEARCH CLIENT")}`}
                                onClick={() => {
                                  setShowAgents(!showAgentes);
                                  setShowNationality(false);
                                  setErrorSearch("");
                                }}
                                onChange={(e: any) => {
                                  filterAgents(e.target.value);
                                  setAgentName(e.target.value);
                                  setShowAgents(true);
                                }}
                                value={agentName ? agentName.trim() : ""}
                                theme={{ isMobile }}
                              />
                              {showAgentes && (
                                <DivContainerAgents
                                  className={"agents-scroll"}
                                  onMouseLeave={() => {
                                    setShowAgents(false);
                                    setAgentName(
                                      agentSelected
                                        ? `${agentSelected?.first_name} ${agentSelected?.last_name}`
                                        : ``
                                    );
                                    //setAgentsFilter(agents);
                                  }}
                                >
                                  {agenstFilter?.map(
                                    (obj: any, index: number) => {
                                      let selected: boolean = false;
                                      selected =
                                        agentSelected?.first_name ==
                                          obj?.first_name &&
                                        agentSelected?.last_name ==
                                          obj?.last_name &&
                                        agentSelected?.email == obj?.email &&
                                        agentSelected?.client_agent_id ==
                                          obj?.client_agent_id &&
                                        agentSelected?.id == obj?.id;
                                      return (
                                        <AgentOption
                                          key={`agents_${index}_${obj?.client_agent_id}`}
                                          theme={{ isSelected: selected }}
                                          onClick={() => {
                                            setAgentSelected({
                                              client_agent_id:
                                                obj?.client_agent_id,
                                              first_name: obj?.first_name,
                                              last_name: obj?.last_name,
                                              email: obj?.email,
                                              id: obj?.id,
                                              trav_title: obj?.trav_title,
                                              nationalityCode: obj?.nationality,
                                            });
                                            setShowAgents(false);
                                            setAgentName(
                                              `${obj?.first_name} ${obj?.last_name}`
                                            );
                                          }}
                                        >
                                          <AgentDivFlex>
                                            <DivAgentImgSelected>
                                              <AgentImgSelected
                                                theme={{ isSelected: selected }}
                                              />
                                            </DivAgentImgSelected>
                                            <DivAgentName
                                              title={`${obj?.first_name} ${obj?.last_name}`}
                                            >
                                              <SpanAgentName>{`${obj?.first_name} ${obj?.last_name}`}</SpanAgentName>
                                            </DivAgentName>
                                          </AgentDivFlex>
                                        </AgentOption>
                                      );
                                    }
                                  )}
                                  {agenstFilter?.length == 0 && (
                                    <>
                                      <LabelNotFound>{`${getLangLabel(
                                        "NO RESULT FOUND"
                                      )}!!`}</LabelNotFound>
                                      <DivImgResultNotFound>
                                        <ImgResultNotFound />
                                      </DivImgResultNotFound>
                                    </>
                                  )}
                                </DivContainerAgents>
                              )}
                            </DivInputSearchAgent>
                            <DivDockBlue>
                              <DickBlue theme={{ isSelected: showAgentes }} />
                            </DivDockBlue>
                          </InputSearchClientContainer>
                        </SearchClientContainer>
                      </DivLeftCliente>
                      <DivRightCliente>
                        <SearchClientNationalityContainer theme={{ isMobile }}>
                          <DivImgNationality>
                            <ImgNationality />
                          </DivImgNationality>
                          <DivInputSearchNationality
                            onClick={() => {
                              setShowNationality(!showNationality);
                              setShowAgents(false);
                              setErrorSearch("");
                            }}
                          >
                            <InputSearchNationality
                              type={"text"}
                              placeholder={`${getLangLabel("NATIONALITY")}`}
                              onChange={(e: any) => {
                                setNationalityName(e.target.value);
                                setShowNationality(true);
                                setShowAgents(false);
                                filterNationality(e.target.value);
                              }}
                              value={nationalityName}
                              theme={{ isMobile }}
                            />
                            {showNationality && (
                              <DivContainerNationality
                                className={"agents-scroll"}
                                onMouseLeave={() => {
                                  setShowNationality(false);
                                  setNationalityName(
                                    nationalitySelected?.nationalityName
                                      ? nationalitySelected?.nationalityName
                                      : ""
                                  );
                                }}
                                theme={{ isMobile }}
                              >
                                {nationalityFilter?.map(
                                  (obj: any, index: number) => {
                                    //console.log(obj);
                                    return (
                                      <NationalityOption
                                        key={`${index}_${obj?.nationalityId}`}
                                        onClick={() => {
                                          setShowNationality(false);
                                          setNationalitySelected({
                                            nationalityId: obj?.nationalityId,
                                            nationalityName:
                                              obj?.nationalityName,
                                            nationalityCode:
                                              obj?.nationalityCode,
                                          });
                                          setNationalityName(
                                            obj?.nationalityName
                                          );
                                        }}
                                        theme={{
                                          isSelected:
                                            nationalitySelected?.nationalityId ==
                                            obj?.nationalityId,
                                        }}
                                      >
                                        <DivANationalityName>
                                          <SpanNationalityName>{`${obj?.nationalityName}`}</SpanNationalityName>
                                        </DivANationalityName>
                                      </NationalityOption>
                                    );
                                  }
                                )}
                                {nationalityFilter?.length == 0 && (
                                  <>
                                    <LabelNotFound>{`${getLangLabel(
                                      "NO RESULT FOUND"
                                    )}!!`}</LabelNotFound>
                                    <DivImgResultNotFound>
                                      <ImgResultNotFound />
                                    </DivImgResultNotFound>
                                  </>
                                )}
                              </DivContainerNationality>
                            )}
                          </DivInputSearchNationality>
                          <DivDockBlue>
                            <DickBlue theme={{ isSelected: showNationality }} />
                          </DivDockBlue>
                        </SearchClientNationalityContainer>
                      </DivRightCliente>
                    </DivFlexB2BSearchOptions>
                  </ColInput>

                  <ColSearchInputMobile
                    xs={12}
                    md={12}
                    className={"p-0 text-center"}
                  >
                    <BtnSearchComponent theme={{ isMobile2: true }} />
                  </ColSearchInputMobile>
                  <LocationsOverlay
                    innerRef={ref}
                    target={target}
                    show={show}
                    suggests={suggests || []}
                    setShow={setShow}
                    handleSetPersonTotal={handleSetPersonTotal}
                    isMobile={false}
                    handleDestinationsOverlay={handleDestinationsOverlay}
                    setRefresh={setRefresh}
                    refresh={refresh}
                    loading={isLoading}
                    setSearchText={setSearchText}
                    actualLocation={searchText ?? ""}
                    setSuggestedListcss={setSuggestedListcss}
                  />

                  {/* <CalendarOverlay
                  innerRef={refCalendar}
                  target={targetCalendar}
                  show={showCalendar}
                  setShow={setShowCalendar}
                  refresh={refresh}
                  isMobile={isMobile}
                  eventOnchange={handlerOnClick}
                  calendarStartDay={calendarStartDay}
                /> */}
                  {showCalendar && (
                    <Overlay
                      label1={getLangLabel("check-in")}
                      label2={getLangLabel("check-out")}
                      show={showCalendar}
                      targetRef={targetCalendar}
                      dateRange={{
                        startDate: moment.isMoment(search.dates.checkIn)
                          ? search.dates.checkIn.toDate()
                          : search.dates.checkIn,
                        endDate: moment.isMoment(search.dates.checkOut)
                          ? search.dates.checkOut.toDate()
                          : search.dates.checkOut,
                        key: "selection",
                      }}
                      onChange={(val) => {
                        setSearchDates(val.startDate, val.endDate);
                      }}
                      handlerClose={() => setShowCalendar(false)}
                      calendarStartDay={weekStartsOn}
                      currentInput={currentDateSelected}
                    />
                  )}
                  <GuestConfigurationOverlay
                    isMobile={false}
                    target={targetGuestConfig}
                    show={showGuest}
                    setShow={setShowGuest}
                    setGuestConfiguration={handleGuestConfiguration}
                    removeGuestConfiguration={handleRemoveGuest}
                    setPersonTotal={handleSetPersonTotal}
                  />
                  <GuestConfigurationModal
                    isMobile={true}
                    target={targetGuestConfig}
                    show={showGuestMobile}
                    setShow={setShowGuestMobile}
                    removeGuestConfiguration={handleRemoveGuest}
                    setPersonTotal={handleSetPersonTotal}
                  />
                  <B2BModalSignIn
                    show={showB2BModalSignIn}
                    handleCloseModal={handleShowB2BModalSignIn}
                    isMobile={isMobile}
                  />
                </RowMain>
              </Element>
            </Col>
            <ColBtnSearch
              xs={IS_B2B && userType == 1 ? 2 : 1}
              theme={{ isFloat }}
            >
              <Row>
                <Col align="center">
                  <BtnSearchComponent theme={{ isMobile2: isMobile }} />
                </Col>
                <Col
                  hidden={IS_B2B && userType == 1 ? false : true}
                  className={"p-2"}
                  align="left"
                >
                  <div style={{ display: "flex" }}>
                    <DivCheckBoxPackage>
                      <CheckBoxPackage
                        type={"checkbox"}
                        onChange={(e: any) => {
                          setErrorSearch("");
                          setPackageChecked(e.target.checked);
                        }}
                        checked={packageCheked}
                      />
                    </DivCheckBoxPackage>
                    <DivLabelPackage>
                      <SpanLabelPackage>{`${getLangLabel(
                        "PACKAGE"
                      )}`}</SpanLabelPackage>
                    </DivLabelPackage>
                  </div>
                </Col>
              </Row>
            </ColBtnSearch>
          </Row>

          {showErrorSearch && (
            <Row>
              <LabelErrorSearch>{showErrorSearch}</LabelErrorSearch>
            </Row>
          )}
        </ColSearch>
      </RowSearch>
    </MainContainer>
  );
};
